import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';

const NavContainer = styled(AppBar)({
    backgroundColor: '#333', // Customize your background color
});

const Logo = styled('div')({
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',
    '& a': {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: '#fff', // Link text color
        '&:hover': {
            textDecoration: 'none',
        },
        '& img': {
            marginRight: '8px', // Adjust spacing between logo and text
            height: '40px', // Set your logo height
        },
        '& h6': {
            fontWeight: 'bold',
            fontSize: '1.5rem', // Font size
            '@media (max-width: 600px)': {
                fontSize: '1.2rem', // Adjust font size for smaller screens
            },
        },
    },
});

const NavLinks = styled('div')({
    display: 'flex',
    alignItems: 'center',
    '& > a': {
        textDecoration: 'none',
        margin: '0 10px',
        '& > button': {
            color: '#fff', // Link text color
            '&:hover': {
                borderBottom: '2px solid #fff', // Underline on hover
            },
        },
    },
});

const DrawerLinks = styled('div')({
    '& a': {
        textDecoration: 'none',
        color: '#333', // Drawer link text color
        display: 'block',
        padding: '10px',
        '&:hover': {
            backgroundColor: '#333', // Background color on hover
            color: '#fff', // Change text color on hover
        },
    },
});

function Navbar() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const handleDrawerLinkClick = () => {
        if (isSmallScreen) {
            toggleDrawer(false)();
        }
    };

    return (
        <NavContainer position="static">
            <Toolbar>
                <Logo>
                    <Link to="/">
                        <img src="/beemo.png" alt="Logo" />
                        <Typography variant="h6">Beemo Bee</Typography>
                    </Link>
                </Logo>
                {isSmallScreen ? (
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                ) : (
                    <NavLinks>
                        <Link to="/"><Button color="inherit">Home</Button></Link>
                        <Link to="/about"><Button color="inherit">About us</Button></Link>
                        <Link to="/contact"><Button color="inherit">Contact us</Button></Link>
                        <Link to="/login"><Button color="inherit">Login</Button></Link>
                        <Link to="/register"><Button variant="outlined" color="inherit">Sign Up</Button></Link>
                    </NavLinks>
                )}
                <Drawer
                    anchor="right"
                    open={drawerOpen}
                    onClose={toggleDrawer(false)}
                    sx={{
                        '& .MuiDrawer-paper': {
                            width: '250px',
                            backgroundColor: '#f0f0f0', // Drawer background color
                        },
                    }}
                >
                    <List>
                        {[
                            { text: 'Home', link: '/' },
                            { text: 'About us', link: '/about' },
                            { text: 'Contact us', link: '/contact' },
                            { text: 'Login', link: '/login' },
                            { text: 'Sign Up', link: '/register' },
                        ].map(({ text, link }) => (
                            <ListItem button key={text}>
                                <DrawerLinks>
                                    <Link to={link} onClick={handleDrawerLinkClick}>
                                        <Typography variant="body1">{text}</Typography>
                                    </Link>
                                </DrawerLinks>
                            </ListItem>
                        ))}
                    </List>
                </Drawer>
            </Toolbar>
        </NavContainer>
    );
}

export default Navbar;
