import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPassword() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const id = searchParams.get('id');

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleResetPassword = async (e) => {
        e.preventDefault(); // Prevent default form submission

        if (newPassword !== confirmPassword) {
            toast.error('Passwords do not match');
            return;
        }

        try {
            await axios.put(`https://5000-amirsa5-erpproject-b9c0ctsagqx.ws-eu106.gitpod.io/api/users/reset/${id}`, {
                newPassword,
                token,
            }).then((response)=>{
               
                toast.success('Password reset successful!');
                console.log('Password reset successful:', response.data);
            })

        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error('Error  : ' + error.response.data.message);
            }
            else {
                toast.error('Error  : ' + error.message);
            }
        }
    };

    return (
        <Container maxWidth="sm" style={{ paddingTop: '20px' }}>
            <Typography variant="h4" gutterBottom align="center">
                Reset Password
            </Typography>
            <form onSubmit={handleResetPassword}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="New Password"
                            fullWidth
                            type="password"
                            value={newPassword}
                            onChange={handleNewPasswordChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Confirm Password"
                            fullWidth
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            fullWidth
                        >
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default ResetPassword;
