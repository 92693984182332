import React from 'react';
import { Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles'; // Import makeStyles from @mui/styles

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
    },
    content: {
        textAlign: 'center',
    },
}));

function NotFound() {
    const classes = useStyles();

    return (
        <Container className={classes.container}>
            <div className={classes.content}>
                <Typography variant="h3" gutterBottom>
                    404 - Not Found
                </Typography>
                <Typography variant="body1">
                    The page you're looking for does not exist.
                </Typography>
            </div>
        </Container>
    );
}

export default NotFound;
