import React, { useState } from 'react';
import { Typography, Container, TextField, Button, Grid } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForgotPassWord() {
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail({ [e.target.name]: e.target.value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('https://5000-amirsa5-erpproject-b9c0ctsagqx.ws-eu106.gitpod.io/api/users/forget-password', email)
                .then((response) => {
                    toast.success('you should recive an email !');
                })
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                toast.error('Error sending an email : ' + error.response.data.message);
            }
            else {
                toast.error('Error sending an email : ' + error.message);
            }
        }
        // Add logic to handle form submission
    };

    return (
        <Container maxWidth="sm" style={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom align="center">
                Forgot Password
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth
                            name="email"
                            type="email"
                            onChange={handleChange}
                            // Add necessary onChange and value props for email input
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Reset Password
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
}

export default ForgotPassWord;
