import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import StaticRoutes from './routes/StaticRoutes';
import AdminRoutes from './routes/AdminRoutes';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Check for the presence of the token in local storage upon component mount
    const token = localStorage.getItem('token');

    if (token) {
      // If token exists, set isLoggedIn to true (user is logged in)
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (token) => {
    // When the user logs in, store the token in local storage
    localStorage.setItem('token', token);
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    // Remove the token from local storage upon logout
    localStorage.removeItem('token');
    setIsLoggedIn(false);
  };

  return (
    <div>
      <Router>
        {isLoggedIn ? (
          <AdminRoutes onLogout={handleLogout} />
        ) : (
          <StaticRoutes onLogin={handleLogin} />
        )}
      </Router>
    </div>
  );
}

export default App;
