import React from 'react'
import { Routes, Route } from 'react-router-dom';
import AdminNavbar from '../components/admin_navbar/AdminNavbar';
import Dashboard from '../pages/admin_pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from '../pages/static_pages/NotFound';



function AdminRoutes() {
    return (
        <>
            <AdminNavbar />
            <Routes>
                <Route path="/admin-dashboard/:id" element={<Dashboard />} />
                <Route path="*" element={<NotFound />} /> {/* Add a NotFound component */}

            </Routes>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        </>

    )
}

export default AdminRoutes