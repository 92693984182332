import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function AdminNavbar() {

  const token = localStorage.getItem('token')
  const decoded = jwtDecode(token);
  const userId = decoded.userId;
  const username = decoded.username

  // Assuming you have a logout function passed as a prop to handle user logout
  const handleLogout = () => {
    // Perform logout functionality
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Welcome {username}
        </Typography>
        <Button color="inherit" component={Link} to={`/admin-dashboard/${userId}`}>
          Dashboard
        </Button>
        <Button color="inherit" component={Link} to="/admin/users">
          Users
        </Button>
        {/* Additional admin-specific buttons */}
        <div>
          {/* You can display user information here if available */}
          <span></span>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default AdminNavbar;
