import React from 'react'
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/static_pages/Home';
import About from '../pages/static_pages/About';
import Contact from '../pages/static_pages/Contact';
import Navbar from '../components/navbar/Navbar';
import Login from '../pages/auth/Login';
import Register from '../pages/auth/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from '../pages/static_pages/NotFound';
import ForgotPassWord from '../pages/static_pages/ForgotPassWord';
import ResetPassword from '../pages/static_pages/ResetPassword';


function StaticRoutes() {

    return (
        <>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<About />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassWord />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<NotFound />} /> {/* Add a NotFound component */}
                
            </Routes>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar={false} />
        </>

    )
}

export default StaticRoutes