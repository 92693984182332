import React, { useState } from 'react';
import { TextField, Button, Typography, Container, Grid } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Register.css';

const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        usage_description: '',
        phone_number: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validateInputs(formData)) {
            toast.error('Please fill in all fields correctly.');
            return;
        }

        axios
            .post('https://5000-amirsa5-erpproject-b9c0ctsagqx.ws-eu106.gitpod.io/api/users/register', formData)
            .then((response) => {
                toast.success("User registered successfully");
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    toast.error('Error logging in: ' + error.response.data.message);
                }
                else {
                    toast.error('Error logging in: ' + error.message);
                }
            });
    };


    const validateInputs = (data) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const minPasswordLength = 6; // Minimum length for the password
        const phoneRegex = /^\d{8}$/; // Regex to match exactly 8 digits

        return (
            data.username.trim() !== '' &&
            data.email.trim() !== '' &&
            emailRegex.test(data.email) && // Validate email format
            data.password.trim().length >= minPasswordLength && // Ensure password meets minimum length
            data.usage_description.trim() !== '' &&
            phoneRegex.test(data.phone_number.trim()) // Ensure phone number is exactly 8 digits
        );
    };


    return (
        <Container maxWidth="sm" className="form-container">
            <Typography variant="h4" gutterBottom align="center">
                User Registration
            </Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Username"
                            fullWidth
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Email"
                            fullWidth
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Password"
                            type="password"
                            fullWidth
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Usage Description"
                            fullWidth
                            name="usage_description"
                            value={formData.usage_description}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Phone Number"
                            fullWidth
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button type="submit" variant="contained" color="primary" fullWidth>
                            Register
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Container>
    );
};

export default Register;
